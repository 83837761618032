import React from 'react';
import ContentHeader from '../components/ContentHeader/ContentHeader';
import Section from '../components/Section/Section';

const TitleBody = ({ title, body }) => {
  return (
    <>
      <ContentHeader title={title} />
      <Section>{body}</Section>
    </>
  );
};

export default TitleBody;
